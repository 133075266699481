import React, { useState, useRef, useEffect, memo } from "react"
import isEqual from "lodash/isEqual"
import { navigate } from "gatsby"
import { RichText } from "prismic-reactjs"

// AWS Amplify
import Amplify, { Auth } from "aws-amplify"

import {
  Textbox,
  PasswordTextbox,
  Button,
  FormWrapper,
  FormValidator,
  checkHaveErrors,
  setLocalStorage,
  getLocalStorage,
  FORGOT_PWD_KEY,
  ROUTES,
  linkResolver,
  htmlSerializerUpdateStyle,
  cognitoConfig,
  USER_INFO_KEY,
  captureException,
  SENTRY_ACTIONS,
  isEmpty,
} from "@lesmills/gatsby-theme-common"

const client = () => Amplify.configure(cognitoConfig)

type Props = {
  data: Object,
  layoutData: Object,
  lang: string,
}

const ResetPassword = ({ data, layoutData, lang }: Props) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [errors, setErrors] = useState({})
  const codeRef = useRef(null)
  const passwordRef = useRef(null)
  const {
    invalid_code = {},
    invalid_password = {},
    new_password_label = {},
    required_code = {},
    required_new_password = {},
    reset_code_label = {},
    reset_password_button_label = {},
    subtitle = {},
    title = {},
    resend_code_text = {},
    code_mismatch = {},
  } = data || {}

  const { les_mills_plus_logo_black } = layoutData || {}

  // Init AWS Amplify
  useEffect(() => {
    client()
  }, [])

  const getValue = current => current.value

  const getValidatorErrors = (code, password) => ({
    code: FormValidator(["REQUIRED", "CODE"], code, {
      requiredError: required_code.text,
      invalidError: invalid_code.text,
    }),
    password: FormValidator(["REQUIRED", "PASSWORD"], password, {
      requiredError: required_new_password.text,
      invalidError: invalid_password.text,
    }),
  })

  const handleSubmit = () => {
    const code = codeRef.current ? getValue(codeRef.current).trim() : ""
    const password = passwordRef.current
      ? getValue(passwordRef.current).trim()
      : ""

    const validatorErrors = getValidatorErrors(code, password)

    setErrors(validatorErrors)

    // Get forgot pwd email from localstorage
    const forgotPwd = getLocalStorage(FORGOT_PWD_KEY)
    const email = forgotPwd && forgotPwd.email

    // if no error
    if (!checkHaveErrors(validatorErrors) && email) {
      handleResetPassword(email, code, password)
    }
  }

  const handleResetPassword = (email, code, password) => {
    setIsProcessing(true)

    // Integrate with API
    Auth.forgotPasswordSubmit(email, code, password)
      .then(() => {
        const userInfo = getLocalStorage(USER_INFO_KEY)

        if (Object.keys(userInfo).length > 0) {
          // back to /edit-personal-infomation if logged in
          navigate(ROUTES(lang).EDIT_PERSONAL_INFORMATION)

          return
        }
        setLocalStorage(FORGOT_PWD_KEY, { success: true })

        // navigate to sign-in if no logged in
        navigate(ROUTES(lang).SIGN_IN)
      })
      .catch(err => {
        setErrors({
          code: err.code === "CodeMismatchException" ? code_mismatch.text : "",
          password:
            err.code === "InvalidParameterException"
              ? invalid_password.text
              : "",
        })
        // Send error to sentry
        captureException({
          action: SENTRY_ACTIONS.FORGOT_PASSWORD_SUBMIT,
          requestVariables: {
            email,
            code,
          },
          ...err,
        })
      })
      .finally(() => setIsProcessing(false))
  }

  const handleOnKeyPress = e => {
    if (e.which === 13) {
      e.preventDefault()
      handleSubmit()
    }
  }

  return (
    <FormWrapper
      logo={les_mills_plus_logo_black}
      title={title.text}
      classNames={{ wrapper: "md:max-h-724 md:h-max-content sign-in-wrapper" }}
      testId="reset-pwd-form"
      lang={lang}
      loaded
      isLoggedIn={!isEmpty(getLocalStorage(USER_INFO_KEY))}
    >
      <RichText
        render={subtitle.raw}
        linkResolver={linkResolver}
        htmlSerializer={htmlSerializerUpdateStyle(
          "",
          "form-description w-btn-lg md md:w-login-description-lg mr-auto ml-auto md:text-2lg text-base text-center text-gray-800 mt-26 mb-26 md:mt-35 md:leading-7none font-base-light leading-normal"
        )}
      />

      <Textbox
        label={reset_code_label.text}
        classNames={{
          textbox:
            "w-textbox-base h-textbox-base md:w-textbox-lg md:h-textbox-lg md:text-2lg",
          label: "md:text-2lg",
        }}
        error={errors.code}
        inputRef={codeRef}
        defaultValue=""
        handleOnKeyPress={handleOnKeyPress}
        testId="reset-pwd-code"
        id="reset-pwd-code"
        disabled={isProcessing}
      />
      <PasswordTextbox
        textbox={{
          label: new_password_label.text,
          inputRef: passwordRef,
          error: errors.password,
          defaultValue: "",
          handleOnKeyPress: handleOnKeyPress,
          testId: "reset-pwd-password",
          id: "password",
          disabled: isProcessing,
        }}
      />
      <Button
        handleOnClick={handleSubmit}
        className="btn btn-primary pt-3 pb-3 min-w-full sm:min-w-290 sm:px-30 md:mb-43 mb-38 md:mt-2"
        disabled={isProcessing}
        submitting={isProcessing}
        testId="reset-pwd-btn"
      >
        {reset_password_button_label.text}
      </Button>
      <div
        className={`text-gray-500 leading-2snug md:mb-40 ml-auto mr-auto md:leading-tight font-base-light${
          isProcessing ? " disabled" : ""
        }`}
        data-cy="resend-code-text"
      >
        <RichText render={resend_code_text.raw} linkResolver={linkResolver} />
      </div>
    </FormWrapper>
  )
}
export default memo(ResetPassword, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
