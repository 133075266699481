import React from "react"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"

import { renderSEO, ROUTES } from "@lesmills/gatsby-theme-common"

import ResetPassword from "../../components/ResetPassword"

type Props = {|
  data: Object,
  pageContext: {
    lang: string,
    id: string,
  },
|}

const ResetPasswordPage = (props: Props) => {
  const { pageContext, data } = props || {}

  const lang = pageContext.unPublishedLang || pageContext.lang
  if (!data) return null

  const resetPasswordData = data.prismicResetPasswordPage.data
  const layoutData = data.prismicLayout && data.prismicLayout.data

  return (
    <>
      {renderSEO(resetPasswordData, ROUTES(lang).RESET_PASSWORD, lang)}
      <ResetPassword
        data={resetPasswordData}
        layoutData={layoutData}
        lang={lang}
      />
    </>
  )
}

export const query = graphql`
  query ResetPasswordPage($lang: String) {
    prismicResetPasswordPage(lang: { eq: $lang }) {
      data {
        subtitle {
          raw
        }
        code_mismatch {
          text
        }
        invalid_code {
          text
        }
        invalid_password {
          text
        }
        new_password_label {
          text
        }
        page_author {
          text
        }
        page_description {
          text
        }
        page_image {
          alt
          url
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_type {
          text
        }
        page_og_title {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        required_code {
          text
        }
        required_new_password {
          text
        }
        resend_code_text {
          raw
        }
        reset_code_label {
          text
        }
        reset_password_button_label {
          text
        }
        title {
          text
        }
      }
    }
    prismicLayout(lang: { eq: $lang }) {
      data {
        les_mills_plus_logo_black {
          alt
          url
        }
      }
    }
  }
`

export default withPreview(ResetPasswordPage)
